
.cadre-nav{z-index: 3;
    width: 100vw; top:140px; left:0; position: sticky;
}.container-nav{
    margin: auto;
    width: min(90vw, 1300px);
    background-color: rgb(30,30,30);                        
    display: flex;justify-content: space-between;
    height: 70px;
}
.container-nav button{
    padding: 0 10px 0 10px ;   font-size: 16px;
    color: white;
    text-transform: uppercase;
    width: auto;
    height: 100%;
    box-sizing: border-box;
    border:none;
    border-bottom: 7px red solid;background-color: rgba(0,0,0,0);
    margin-left:30px;
    display: flex; align-items: center; justify-content: center;
}
.container-nav button.rouge{
    background-color: red;
    padding: 0 10px 0 10px;
}
img{
    height: 50%;margin-right: 10px;
}