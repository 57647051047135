.theme{
    top:0; left:0; width: min(1300px,100vw);position:absolute;
}
.theme div.titre{height: 80px; width: 100vw;
    text-transform: uppercase; font-size: 27px; font-weight: bold;
background-color: rgb(50,50,50); color: white; 
}
.theme div.liste{
    width: 100vw;height: fit-content;
    display: flex; flex-direction: column;align-items: center;
}
.theme div.liste div.event{background-color: pink;
    overflow: hidden; border-radius: 20px;box-shadow: 0 0 10px rgba(0,0,0,.5); box-sizing: border-box;
    display: flex;justify-content: space-between;
    margin-top: 20px; width: 90vw;height:fit-content;
}
.grand{
    flex-direction: column; 
    height: 55vw;
    width: 90vw;
}
.petit{
    flex-direction: row;
    height: 45vw; width: 90vw;
}

.descr{
 font-weight: bold;
}
.grand .descr{
    display: flex; align-items: center; justify-content: space-between;
    width:100%; height: 10vw;
}
.petit .descr{
    width: 45vw;height: 45vw;
}
.petit .illus{
    height: 45vw; width: 45vw;background-color: blue;
}
.grand .illus{
    width:90vw; height: 45vw;background-color: orange;
}
.illus img{ height: 100%; width: 100%;}
.theme button.retour{
    position: fixed;top:0; left:0;
}
.theme .event button{
    background-color: rgb(185, 56, 56);
    color: white;
    font-size: 15px;
    border-radius: 8px;
    border: none;
    padding: 5px 15px;
    box-sizing: border-box;
}
.event.petit button{
    margin-top:20px;
}
.theme .event .descr {
    box-sizing: border-box;
    padding: 0 5%;
}