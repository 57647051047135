.container-deadline{
   padding-top: 40px;
    text-transform: uppercase;
    width: min(100vw, 1100px); margin: auto;
}
.container-deadline div.dessus{
    height: 70px; color: rgb(100, 100, 100);
    display: flex; align-items: center; justify-content: space-between;
}
.container-deadline div.dessus div.titre{
    height: 100%;background-color: aliceblue;
    text-align: start;align-items: flex-start;
    width: max(250px,30%); 
    font-size:clamp(20px,25px, 7vw);
    border-bottom: 4px rgb(100,100,100) solid ;
    
}
.container-deadline div.dessus div.navig{justify-content: center;
    display: flex;
}
    .container-deadline div.fleche{
        margin: 10px;
    width: 30px; height: 30px;
}
.container-deadline div.fleche img{
border-color: rgb(100,100,100);
}
/*////////////////////////////////*/
.cadre-affiches{
    height: fit-content;
    overflow:hidden;
}
.affiches{
    height: fit-content;
    width: fit-content;
    position: relative;
    display: flex;
    flex-wrap: nowrap; 
}
.ev-grand{
   
margin: 12.5px;
width: min(90vw,500px);
height: min(72vw,250px);background-size: contain; background-repeat: no-repeat;
display: flex; flex-direction: column;
 align-items: center; justify-content: center;
}
.ev-petit{margin: 12.5px;
    height: min(72vw,250px);width: min(36vw,200px);
    display: flex; flex-direction: column;justify-content: space-between;
}
.ev-grand div.intitule{
    color:white; font-weight: bold; 
    text-shadow: 0 0 5px black, 0 0 2px black, 0 0 1px black, 0 0 8px black;
     font-size: clamp( 12px,33px,8vw);
     margin-bottom: 10%;
}
.ev-petit div.intitule{
 font-size: 17px; color: black;font-weight: bold; 
}

img[alt='petite']{
    height: min(36vw,200px); width: min(200px,36vw);
}
.ev-petit div.legende{background-color: green; overflow: visible;
    border-top: 2px grey solid;
    height: min(9vw,50px);width: 100%;font-weight: bold;
    display: flex;align-items:center; justify-content: space-between;
}
.container-deadline button{
        background-color:rgb(245, 58, 58);
        color: white;
        text-transform: uppercase;
        border: none;box-sizing: border-box;
        font-size: 15px;
        padding: 10px 20px;
        margin-top:0;
        font-weight: bold; 
    }

    @media screen and (max-width : 1100px) {
        .ev-petit, .ev-grand{margin: 2vw; text-align: center;}
        .ev-grand{
            justify-content: flex-end;
        }
        .ev-grand div.intitule{
            font-size: 17px; color: black;font-weight: bold; text-shadow: none;
            margin-bottom: 2%;
        }
    }

