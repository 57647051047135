.container-header{z-index: 3;
    background-color: white;
    position: sticky; top:0; left:0;
    width: 100vw;
}
.haut{margin: auto;
    display: flex;
    height: 40px;
    width:min(100vw, 1300px); 
}
.haut div{ 
    font-size: min(3.5vw,20px);
height: 100%; width: 33.33%;
border: 1px gray solid; box-sizing: border-box;
display: flex; align-items: center; justify-content: center;text-align: center;
text-transform: uppercase;
}
.bas{
    margin: auto;
    width:min(100vw, 1040px); 
    height:80px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: repeat(2,1fr);
}
.bas div{height:80px;width: 100%;
    display:flex; align-items: center; justify-content: center;text-align: center;
}
.melwin{
    color: red; font-size: 40px; font-weight: bolder;
    grid-row: 1;grid-column: 1;
}
.rech{background-color: blue;
    width: 100%;height: 100px;overflow: hidden;
    grid-row: 1;grid-column: 2/4;
    border: 1px gray solid;
}
.rech div{
    align-items: center;
}
.rech div:first-child{
 width: 80%;height: 100%; border-right: 1px grey solid;
}

.rech div:last-child{
    width: 20%; height: 100%;
    background-color: red;
}
img[alt='bouton rechercher catégories']{
    height: 70%; width: auto;
}
.reseaux{
    grid-row: 1;grid-column: 4;
}
.reseaux div{
    display: flex; align-items: center; justify-content: center;
    margin-left: 15px;
    border:gray solid 1px;
    border-radius: 100%;
    height: 40px; width: 40px;
}
.reseaux div img{
    height: 50%;margin: auto;
}
.rech div.defilement{
    background-color: white;
    display: flex; flex-direction: column;
}

button.invis{
    background-color:rgba(0,0,0,0);
    border: none;
    text-transform: uppercase;
    display: flex; align-items: center; justify-content: center;
}
@media screen and (max-width:700px){
    .bas{
        height: 140px;overflow: hidden;
    }
    .bas div.melwin{height: 70px;
        grid-row: 1;grid-column: 1/3;
    }
    .bas div.reseaux{height: 70px;
        grid-row: 1;grid-column: 3/5;
    }
    .bas div.rech{height: 40px;width: 90vw;margin: auto;
        grid-row: 2;grid-column: 1/5;
    }
}