.container-categ{ 
    width: min(100vw,1100px);
    margin: auto;
}
.liste{
    height: 80px; width: 100%;
    display: flex;align-items: center;justify-content: space-around;
    font-size: 15px;
}
.cases{
    text-transform: uppercase;font-size: 25px;
    color: white;text-shadow:0 0 20px black, 0 0 10px black,0 0 5px black, 0 0 2px black;
    display: flex; flex-direction: row; flex-wrap: wrap;justify-content: center;
    width: 100%;
}
.cases>div{
    background-size: contain;
    border-radius: 15px;
    box-shadow: 0 0 10px rgb(30,30,30);
    height: min(240px,33.75vw); width:min(45vw,320px);
    margin: min(2.5vw,15px);text-align: center;
    display: flex; 
    align-items: center; justify-content: center;
    flex-direction: column;
}
.container-categ button{
    display: flex; align-items: center; justify-content: center;
    background-color:rgb(245, 58, 58);
    color: white;
    text-transform: uppercase;
    border: none;
    font-size: 15px;font-weight: bold;
    padding: 10px 20px;
    margin-top: 50px;
    box-sizing: border-box;
    border-radius: 5px;
}
@media screen and (max-width: 415px){
    .cases>div{
        width:90vw;
        height:67.5vw ;
    }
}
@media screen and (max-width:600px){
    .liste{display: none;}
    .cases>div{
        justify-content: space-between;
        padding-bottom: 4%;box-sizing: border-box;
    }
    .container-categ button{
        opacity: .9; height: fit-content;
        box-sizing: border-box; 
        border-radius: 5px;margin-top: 0;
    }
}
