#mobile{
display: none;
}
.container-banderolle{
    height: 500px;
    width: 1301px;margin: auto;
    color: white; text-transform: uppercase;
    display: flex; align-items:center; justify-content: space-between;
    background-size: cover;
}
.fleche{
    height: 50px; width: 50px;
    margin: 30px;
}
.titre{
    display: flex;
    flex-direction: column;text-align: center;
    align-items: center; justify-content: center;
}
.fleche img{
    width: 50%;
    padding: 10px;
    border-radius: 100%;
    border: rgba(255, 255, 255, 0.899) 2px solid;
}
.container-banderolle.titre{
    text-shadow: 0 0 10px black;
}
.grand{
    font-weight: bolder;
    font-size: min(55px,8vw);
}
.moyen{
font-size: min(40px,5vw);
}
.petit{
    font-size: min(30px,4vw);
}
@media screen and (max-width: 1300px){
    .container-banderolle{
        height: 50vw;width: 100vw;
        background-size: contain;
        background-repeat: no-repeat;
        font-size: 10px;
    }
    #mobile{display: flex;}
    #bureau{display: none;}
}